import {Component} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {defaultNewWaterSourceWizard} from "./campaign/campaign-edit/wizard/default-new-water-source-wizard";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  protected readonly defaultNewWaterSourceWizard = defaultNewWaterSourceWizard;

  constructor(translate: TranslateService) {
    translate.use('en');
  }
}
