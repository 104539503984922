import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {authGuard, mainGuard} from "./auth/login/auth.service";
import {AuthComponent} from "./auth/login/auth.component";
import {ContentComponent} from "./content/content.component";

const routes: Routes = [
  {
    path: 'main',
    component: ContentComponent,
    canActivate: [mainGuard],
    loadChildren: () => import('./content/content.module').then(m => m.ContentModule)
  },
  {
    path: 'auth',
    component: AuthComponent,
    canActivate: [authGuard]
  },
  {path: '', redirectTo: 'main', pathMatch: 'full'},
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
