import {inject, Injectable} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {Observable, tap} from "rxjs";
import {CookieService} from "ngx-cookie-service";
import {CanActivateFn, CanMatchFn, Router, UrlTree} from "@angular/router";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private http: HttpClient,
              private cookieService: CookieService) {
  }

  login(username: string, password: string): Observable<any> {
    const loginApi = `${environment.BASE_URL}/auth/login`;

    return this.http.post(loginApi, {
      "phoneNumber": username,
      "password": password
    }).pipe(
      tap((response: any) => {
        if (response) {
          this.cookieService.set('token', response.token, {
            expires: 365,
            path: '/',
            sameSite: 'Lax',
            secure: true
          });
        }
      })
    );
  }

  isLoggedIn(): boolean {
    return this.cookieService.check('token');
  }
}

export const authGuard: CanActivateFn =
  (): boolean | UrlTree => {
    return inject(AuthService).isLoggedIn()
      ? inject(Router).createUrlTree(['main'])
      : true;
  };

export const mainGuard: CanMatchFn =
  (): boolean | UrlTree => {
    return inject(AuthService).isLoggedIn()
      ? true
      : inject(Router).createUrlTree(['auth']);
  };
