import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import {SpinnerService} from "../spinner/spinner.service";

@Injectable()
export class RequestCounterInterceptor implements HttpInterceptor {

  constructor(private spinnerService: SpinnerService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.spinnerService.loadingOn();
    return next.handle(req).pipe(
      finalize(() => this.spinnerService.loadingOff())
    );
  }
}
