import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppComponent} from './app.component';
import {AuthComponent} from './auth/login/auth.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import {CookieService} from "ngx-cookie-service";
import {TokenInterceptor} from "./interceptor/token.interceptor";
import {AppRoutingModule} from "./app-routing.module";
import {TranslateCompiler, TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {NgSelectModule} from "@ng-select/ng-select";
import {ToastrModule} from "ngx-toastr";
import {TranslateMessageFormatCompiler} from "ngx-translate-messageformat-compiler";
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {MatNativeDateModule} from "@angular/material/core";
import {Overlay} from "@angular/cdk/overlay";
import {MAT_DATEPICKER_SCROLL_STRATEGY} from "@angular/material/datepicker";
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {RequestCounterInterceptor} from "./interceptor/request-count.interceptor";
import {OnlyDigitsDirective} from "./directive/only-digits.directive";
import {MatButton} from "@angular/material/button";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({ declarations: [
        AppComponent,
        AuthComponent,
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        NgSelectModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (HttpLoaderFactory),
                deps: [HttpClient]
            },
            compiler: {
                provide: TranslateCompiler,
                useClass: TranslateMessageFormatCompiler
            }
        }),
        ToastrModule.forRoot(),
        NgbModule,
        MatNativeDateModule,
        OnlyDigitsDirective, MatButton], providers: [
        CookieService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        { provide: HTTP_INTERCEPTORS,
            useClass: RequestCounterInterceptor,
            multi: true
        },
        {
            provide: MAT_DATEPICKER_SCROLL_STRATEGY,
            useFactory: (overlay: Overlay) => () => overlay.scrollStrategies.reposition(),
            deps: [Overlay]
        },
        provideAnimationsAsync(),
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {
}
